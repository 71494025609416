import React from 'react'
import {Link} from 'components/link'

import filterStyles from './filterButton.module.css'

export const FilterButtons = ({categories}) => (
  <div className={filterStyles.container}>
    {categories.map(({name, path}) => (
      <Button to={path}>{name}</Button>
    ))}
  </div>
)

const Button =({active, ...props}) => {

  return (

      <Link className={filterStyles.button} activeClassName={filterStyles.activeButton} {...props}/>


  )

}
