import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import CardStyle from './card.module.css'
import {Link} from 'components/link'

const MemberCardContent = ({featured_media, name, country, link}) => (
  <div className={CardStyle.container}>
    {featured_media && featured_media.localFile && featured_media.localFile.childImageSharp &&
     <div className={CardStyle.image}>
       <Img fluid={featured_media.localFile.childImageSharp.fluid} style={{width:'80%', height:'auto'}}/>
     </div>
    }
    <div className="mt-auto">
      <div className={CardStyle.name} dangerouslySetInnerHTML={{__html:name}}/>
      <div className={CardStyle.country} dangerouslySetInnerHTML={{__html:country}}/>
    </div>
  </div>

)

export const MemberCard = (props) => {
  if(props.link){
    return(
      <Link className="cursor-pointer h-full" to={props.link} target='_blank'>
        <MemberCardContent {...props}/>
      </Link>
    )
  }else {
    return (
      <MemberCardContent {...props}/>
    )
  }
}
