import React from 'react'
import {graphql} from 'gatsby'
import {withGrid} from 'components/grid'
import {MemberCard} from 'components/members/card'
import {FilterButtons} from 'components/members/filterButtons'
import {TitleBanner} from 'components/banners/titleBanner'
import BottomNavigation from 'components/BottomNavigation'
import Section from 'components/section'; 

import Layout from "../components/layout"



export default  (props) => {
  const membersToRender = props.data.allWordpressWpMembers.nodes
        .map((member) => ({
          featured_media: member.featured_media,
      name: member.title,
      country: member.acf.Country,
      link: member.acf.link
        }))
  const categories = props.data.allWordpressWpMembersType.nodes.filter((category) => category.count > 0)
  const Grid = withGrid(MemberCard, membersToRender)
  return (
    <Layout  context = {props.pageContext}
             location = {props.location}
             ancestors={props.data.wordpressPage.ancestors}
    >
      <TitleBanner title={props.data.wordpressPage.title} />
      <FilterButtons categories={categories} />
      <Grid/>
      {props.data.wordpressPage.showPageGroupWidget && props.data.wordpressPage.siblingPages && props.data.wordpressPage.siblingPages.length > 0 &&
       <Section>
         <BottomNavigation items={props.data.wordpressPage.siblingPages} />
       </Section>
      }

    </Layout>
  )
}

export const query = graphql`
  query membersQuery($wordpress_id: Int!){
    wordpressPage(template:{eq:"archive-members.php"}) {
      title
      showPageGroupWidget
      ancestors {
        link
        label
      }
      siblingPages {
        title
        excerpt
        path
      }
    }

    allWordpressWpMembers(filter:{members_type:{in: [$wordpress_id]}}, sort:{fields:menu_order}) {
      nodes
      {
        title
        members_type 
        featured_media {
          localFile {
            childImageSharp {
              fluid( maxWidth: 204) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        acf {
          Country
          link
 
        }
      }
    }
    allWordpressWpMembersType {
      nodes {
        name
        count
        path 
      }
    }
  }
`
